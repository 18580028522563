import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import Img from 'gatsby-image/withIEPolyfill';

import Slider from 'react-slick';

const Photos = styled(Container)`
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 16px;
  }
  h4 {
    ${props => props.theme.main.fonts.displaySmall}
  }
  .slick-list {
    overflow: visible;
  }
`;

const PhotoGrid = styled(Slider)`
  margin-left: -10px;
  margin-right: -10px;
  .slick-slide {
    padding: 10px;
  }
`;

export default ({images}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <Photos>
      <h3>We're also humans</h3>
      <h4>We don’t just write code, develop amazing applications, or solve challenging integration problems. We’re also about having fun and connecting with each other.</h4>
      <PhotoGrid {...settings}>
        {images.map((image, i) => (
          <Img key={i} fluid={image.fluid} />
        ))}
      </PhotoGrid>
    </Photos>);
}