import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container, Row, Col, TabContent, TabPane, Progress } from 'reactstrap';

import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';

const Technology = styled(Container)`
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 8px;
  }
  h4 {
    ${props => props.theme.main.fonts.displayLargeG}
  }
  margin-bottom: 36px;
`;

const Category = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  .tab-content {
    ${props => props.theme.main.fonts.body}
    p {
      margin-bottom: 8px;
    }
    
  }
  .buttons {
    display: flex;
    svg {
      margin: 5px;
      cursor: pointer;
      &[disabled] {
        fill: ${props => props.theme.main.colors.grey.dark};
        cursor: default;
      }
    }
  }
`;

const StyledProgress = styled(Progress)`
  height: 5px;
  margin-bottom: 24px;
`;

const TechContent = styled(TabContent)`
  ul {
    li {
      ${props => props.theme.main.fonts.displayMedium}
      margin-bottom: 8px;
    }
  }
  ${breakpoint('md')`
    margin-left: 64px;
  `}
`;

const technologiesContent = [
  {
    id: "0",
    "name": "Web application development",
    "mobile_name": "Web Applications",
    "examples": [
      "React",
      "Vue",
      "Contentful"
    ]
  },
  {
    id: "1",
    "name": "Corporate Systems Integration",
    "mobile_name": "Corporate Systems Integration",
    "examples": [
      "MS SQL Server",
      "Postgres",
      "MySQL",
      "MongoDB",
      "C#",
      "Java",
      "Javascript"
    ]
  },
  {
    id: "2",
    "name": "Business Analysis",
    "mobile_name": "Business Analysis",
    "examples": [
      "AWS",
      "Microsoft Azure",
      "Digital Ocean"
    ]
  },
  /*{
    id: "3",
    "name": "Native Mobile application development",
    "mobile_name": "Mobile Applications",
    "examples": [
      "Java for Android",
      "Kotlin for Android",
      "Swift for iOS",
      "React Native"
    ]
  },*/
]

export default () => {
  const [index, setIndex] = useState(0);
  const tabsCount = technologiesContent.length;

  const clickNext = () => {
      if (index !== tabsCount - 1) setIndex(index + 1);
  }
  const clickPrev = () => {
      if (index !== 0) setIndex(index -1);
  }

  return (
    <Technology id="technology">
      <h3>Technologies</h3>
      <h4>The software we use to actually build the things</h4>
      <Row>
        <Col md="6">
          <Category>
            <TabContent activeTab={index}>
              {technologiesContent.map((tech, i) => (
                <TabPane tabId={parseInt(i)} key={tech.id}>
                  <p>
                    {tech.name}
                  </p>
                </TabPane>
              ))}
            </TabContent>
            <div className="buttons">
              <FaChevronLeft onClick={clickPrev} disabled={(index === 0)} />
              <FaChevronRight onClick={clickNext} disabled={(index === tabsCount - 1)} />
            </div>
          </Category>
          <StyledProgress value={(index + 1) / tabsCount * 100}/> 
        </Col>
        <Col md="6">
          <TechContent activeTab={index}>
            {technologiesContent.map((tech, i) => (
                <TabPane key={tech.id} tabId={parseInt(i)}>
                  <ul>
                    {tech.examples.map((example, j) => (
                      <li key={j}> {example}</li>
                    ))}
                  </ul>
                </TabPane>
              ))}
          </TechContent>
        </Col>
      </Row>
    </Technology>);
}