import React from 'react';

import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';
import About from 'components/About/About';
import { graphql } from 'gatsby';

const AboutPage = ({data}) => (
	<Layout>
		<SEO title="About Us" />
		<About data={data} />
	</Layout>
)

export default AboutPage

export const query = graphql`
  query {
    file(relativePath: { eq: "calico-about.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulHumanImages(title: {eq: "Calico Site"}) {
      images {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
