import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';

import circleImage from 'images/orange.circle.svg';

const Header = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 150px auto 1fr 1fr;
  grid-template-rows: 150px auto 1fr 1fr;
`;

const StyledContainer = styled(Container)`
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-column: 1 / 7;
  z-index: 2;

  h1 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 16px;
  }
  p {
    ${props => props.theme.main.fonts.body}
    color: ${props => props.theme.main.colors.grey.slate};
  }

  ${breakpoint('md')`
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1 / 5;
    -ms-grid-row-align: center;
    align-self: center;
    h1 {
      width: 50%;
    }
    p {
      width: 50%;
    }
  `}

  ${breakpoint('lg')`
    -ms-grid-row: 2;
    -ms-grid-row-span: 3;
    grid-row: 2 / 5;
    -ms-grid-row-align: start;
    align-self: start;
`}
`;

const Image = styled.div`
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / 5;
  -ms-grid-column: 3;
  -ms-grid-column-span: 4;
  grid-column: 1 / 7;
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: 20px;

  ${breakpoint('md')`
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    grid-row: 1 / 5;
    -ms-grid-column: 4;
    -ms-grid-column-span: 2;
    grid-column: 4 / 7;
    -ms-grid-row-align: center;
    align-self: center;
    margin-right: 0;
    padding-top:60px
  `}

  position: relative;

  .gatsby-image-wrapper {
    border-radius: 20px;
  }
`;

const Background = styled.div`
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / 5;
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
  grid-column: 4 / 7;
  -ms-grid-column-align: right;
  justify-self: right;
  text-align: right;

  ${breakpoint('md')`
    margin-top: -140px;
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1 / 5;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-column: 5 / 7;
  `}

  svg {
    width: 100%;
    height: 100%;
  }
`;


export default ({file}) => {
  return (
    <Header>
      <StyledContainer>
        <h1>Your friendly dev shop</h1>
        <p>We create digital products using effective methodical approaches to software and the best technology to do so.</p>
      </StyledContainer>
      <Image>
        <Img fluid={file.childImageSharp.fluid} />
      </Image>
      <Background>
        <img src={circleImage} />
      </Background>
    </Header>
  );
}
