import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Header from './Header/Header';
import Photos from './Photos/Photos';
import Technology from './Technology/Technology';
import Process from './Process/Process';

const About = styled.div`
  margin-top: 50px;
  padding-bottom: 32px;

  ${breakpoint('lg')`
    margin-top: -75px;
  `}
`;

export default ({data}) => {
  return (
    <About>
      <Header file={data.file} />
      <Technology />
      <Process />
      <Photos images={data.contentfulHumanImages.images} />
    </About>);
}
